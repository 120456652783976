.react-tabs {
  color: #464a53;
}

.react-tabs__tab-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 0 24px 0;
  border-bottom: 1px solid #d9dbdf;
}

.react-tabs__tab {
  color: #3b5275;
  font-size: 16px;
  line-height: 24px;
  padding: 0 0 22px 0;
  white-space: nowrap;
  background: transparent;
  transition: color 0.2s ease-out;
}

.react-tabs__tab:not(:last-of-type) {
  margin-right: 48px !important;
}

.react-tabs__tab--selected {
  color: #8798b0;
  border-color: transparent;
  border-bottom: 4px solid #8798b0;
}

.react-tabs__tab:focus,
.react-tabs__tab--selected:focus {
  margin: 0;
}

.react-tabs__tab:focus,
.react-tabs__tab--selected:focus {
  box-shadow: none;
  border-radius: 0;
  border-color: transparent;
}
